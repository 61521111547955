import {PhoneEnabled} from '@mui/icons-material';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import {useLsrcPhoneNumberQuery} from '@/queries/lsrc';
import type {LeadToken} from '@/types/lead-token';

const PhoneButtonDisplay = ({leadToken, sourceId} : {leadToken : LeadToken; sourceId : string}) : JSX.Element => {
    const phoneNumberQuery = useLsrcPhoneNumberQuery(sourceId);
    const eventMutation = useCreateLeadTokenEventMutation();

    const handleClick = async () => {
        await eventMutation.mutateAsync({leadTokenId: leadToken.id, type: 'click', name: 'phone_button'});
    };

    return phoneNumberQuery.data
        ? <>
            <Box
                color="#000"
                bgcolor={'#f9ac4d'}
                borderRadius={6}
                px={4}
                py={1.5}
                display="flex"
                alignItems="center"
            >
                <PhoneEnabled/> <Typography variant={'body1'} display="inline">
                    <a
                        style={{textDecoration: 'none', color: 'inherit', fontWeight: '700'}}
                        href={'tel:' + phoneNumberQuery.data}
                        onClick={handleClick}
                    >
                        CALL NOW {phoneNumberQuery.data}
                    </a>
                </Typography>
            </Box>
        </>
        : <></>;
};

const PhoneButton = ({leadToken} : {leadToken : LeadToken}) : JSX.Element => {
    if (!leadToken.sourceId) {
        return <></>;
    }

    return <PhoneButtonDisplay leadToken={leadToken} sourceId={leadToken.sourceId}/>;
};

export default PhoneButton;
