import {Stack} from '@mui/material';
import Typography from '@mui/material/Typography';
import type React from 'react';
import {useEffect, useRef} from 'react';
import DiscoverCarousel from '@/components/DiscoverCarousel';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';

const DiscoverBeauty = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const eventMutation = useCreateLeadTokenEventMutation();

    const discoverBeautyRef = useRef<HTMLElement>(null);
    const isDiscoverBeautyVisible = useOnScreen(discoverBeautyRef);

    useEffect(() => {
        if (isDiscoverBeautyVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'discover_beauty'});
        }
    }, [isDiscoverBeautyVisible]);

    return (
        <Stack
            sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                backgroundColor: '#f8f9fa',
            }}
        >
            <Typography
                ref={discoverBeautyRef}
                sx={{
                    color: '#6cc14c',
                    fontWeight: '700',
                    fontSize: '40px',
                    p: 5,
                }}
            >
                Discover Beauty That Fits Your Budget.
            </Typography>
            <DiscoverCarousel/>
        </Stack>
    );
};

export default DiscoverBeauty;
