import DesignServicesIcon from '@mui/icons-material/DesignServices';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HardwareIcon from '@mui/icons-material/Hardware';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {Box, Divider, Paper, Stack, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef} from 'react';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import BeautyImage from 'src/assets/process-beauty-image.jpg';

const OurProcess = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('lg'));

    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'our_process'});
        }
    }, [isSectionVisible]);

    return <Grid container xs={12} sx={{justifyContent: 'center', backgroundColor: '#fbfaf7', pb: 5}}>
        <Grid container xs={12} sx={{justifyContent: 'center', textAlign: 'center', my: mobile ? 5 : 10}}>
            <Typography variant={'h3'} sx={{fontSize: mobile ? '1.8em' : '3em'}}>
                Our 4-Step Hassle-Free Process
            </Typography>
        </Grid>
        <Grid container xs={12} md={6} spacing={2} sx={{justifyContent: 'center'}}>
            <Stack>
                <Paper elevation={5} sx={{padding: 2, ml: mobile ? 2 : 25, mr: mobile ? 2 : 0, borderRadius: '16px'}}>
                    <Box sx={{display: 'flex', height: '100%'}}>
                        <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <HandshakeIcon sx={{fontSize: '72px', color: '#6cc14c'}}/>
                        </Box>
                        <Box sx={{flex: 4}}>
                            <Stack>
                                <Typography sx={{fontWeight: 500}}>STEP 1</Typography>
                                <Typography variant={'h5'}>CONSULTATION</Typography>
                                <Divider sx={{width: '25%', backgroundColor: '#f9ac4d', height: '2px', my: 1}}/>
                                <Typography>
                                    Our design consultants provide in-home consultations. They listen to your design
                                    desires and provide a plan.
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Paper>
                <Paper elevation={5} sx={{
                    padding: 2,
                    ml: mobile ? 2 : 25,
                    mr: mobile ? 2 : 0,
                    mt: 5,
                    borderRadius: '16px',
                }}>
                    <Box sx={{display: 'flex', height: '100%'}}>
                        <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <DesignServicesIcon sx={{fontSize: '72px', color: '#6cc14c'}}/>
                        </Box>
                        <Box sx={{flex: 4}}>
                            <Stack>
                                <Typography sx={{fontWeight: 500}}>STEP 2</Typography>
                                <Typography variant={'h5'}>CUSTOM BUILD</Typography>
                                <Divider sx={{width: '25%', backgroundColor: '#f9ac4d', height: '2px', my: 1}}/>
                                <Typography>
                                    Accurate measurements are taken, and last-minute questions are answered before
                                    sending in the build order for your customized windows/doors.
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Paper>
                <Paper elevation={5} sx={{
                    padding: 2,
                    ml: mobile ? 2 : 25,
                    mr: mobile ? 2 : 0,
                    mt: 5,
                    borderRadius: '16px',
                }}>
                    <Box sx={{display: 'flex', height: '100%'}}>
                        <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <HardwareIcon sx={{fontSize: '72px', color: '#6cc14c'}}/>
                        </Box>
                        <Box sx={{flex: 4}}>
                            <Stack>
                                <Typography sx={{fontWeight: 500}}>STEP 3</Typography>
                                <Typography variant={'h5'}>INSTALLATION</Typography>
                                <Divider sx={{width: '25%', backgroundColor: '#f9ac4d', height: '2px', my: 1}}/>
                                <Typography>
                                    Our Installation Team takes extreme care and thoroughly cleans up after the job is
                                    completed. They even install in all four seasons!
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Paper>
                <Paper elevation={5} sx={{
                    padding: 2,
                    ml: mobile ? 2 : 25,
                    mr: mobile ? 2 : 0,
                    my: 5,
                    borderRadius: '16px',
                }}>
                    <Box sx={{display: 'flex', height: '100%'}}>
                        <Box sx={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                            <VerifiedUserIcon sx={{fontSize: '72px', color: '#6cc14c'}}/>
                        </Box>
                        <Box sx={{flex: 4}}>
                            <Stack>
                                <Typography sx={{fontWeight: 500}}>STEP 4</Typography>
                                <Typography variant={'h5'}>CUSTOMER CARE</Typography>
                                <Divider sx={{width: '25%', backgroundColor: '#f9ac4d', height: '2px', my: 1}}/>
                                <Typography>
                                    Renewal by Andersen windows and doors are backed by one of the strongest limited
                                    warranties in the industry<sup>1</sup>.
                                </Typography>
                            </Stack>
                        </Box>
                    </Box>
                </Paper>
            </Stack>
        </Grid>
        <Grid container xs={12} md={6} sx={{justifyContent: 'center'}}>
            <Box
                component={'img'}
                alt="Renewal by Anderson"
                src={BeautyImage}
                sx={{
                    maxWidth: mobile ? '100%' : '75%',
                    padding: mobile ? 2 : 0,
                }}
            />
        </Grid>
    </Grid>;
};

export default OurProcess;
