import StarIcon from '@mui/icons-material/Star';
import {Button, Stack, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import type React from 'react';
import {useEffect, useRef} from 'react';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import {useLsrcPhoneNumberQuery} from '@/queries/lsrc';
import type {LeadToken} from '@/types/lead-token';

const HeroSection = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('lg'));
    const phoneNumberQuery = useLsrcPhoneNumberQuery(leadToken.sourceId);

    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'hero_section'});
        }
    }, [isSectionVisible]);

    const scrollToQuiz = () => {
        window.scrollBy({left: 0, top: 1250, behavior: 'smooth'});
    };

    return <Grid container xs={12}>
        <Grid xs={12} md={6} sx={{backgroundColor: '#fbfaf7', pt: mobile ? 2 : 15}}>
            <Stack alignItems={'center'} textAlign={'center'}>
                <Typography variant={'h3'} px={5}>
                    Get These Stunning New Windows and Doors in Your Home
                </Typography>
                <Grid sx={{mt: 4, mb: 6}}>
                    <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
                    <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
                    <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
                    <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
                    <StarIcon fontSize={'large'} sx={{color: '#f9ac4d'}}/>
                    <Typography variant={'body1'} sx={{
                        textTransform: 'uppercase',
                        color: '#50565a',
                        fontWeight: 700,
                    }}>Join The 500,000+ Homeowners That Recommend Renewal by Andersen*</Typography>
                </Grid>

                <Button sx={{
                    color: '#000',
                    bgcolor: '#f9ac4d',
                    borderRadius: 6,
                    px: 4,
                    py: 1.5,
                    '&.MuiButtonBase-root:hover': {
                        bgcolor: '#f9ac4d',
                    },
                    mx: 2,
                }} onClick={() => {
                    scrollToQuiz();
                }}>
                    Take Quiz to See Your Local Offer
                </Button>
                <Typography sx={{
                    my: 2,
                    fontWeight: 500,
                    textTransform: 'uppercase',
                    textDecoration: 'underline',
                }}>
                    <a href={'tel:' + (phoneNumberQuery.data ?? '18005011400')} style={{color: '#001722'}}>
                        Or Call {phoneNumberQuery.data ?? '1-800-501-1400'} Now
                    </a>
                </Typography>
            </Stack>
        </Grid>
        <Grid xs={12} md={6}>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} textAlign={'center'}
                sx={{minHeight: '500px'}}>
                <iframe
                    title="vimeo-player"
                    src="https://player.vimeo.com/video/1015851499?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    width="940"
                    height="660"
                    frameBorder="0"
                    allowFullScreen/>
            </Grid>
        </Grid>
    </Grid>;
};

export default HeroSection;
