import Brightness5Icon from '@mui/icons-material/Brightness5';
import HandymanIcon from '@mui/icons-material/Handyman';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import {Box, Stack, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useEffect, useRef} from 'react';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import WindowCornerImage from 'src/assets/window-corner.png';

const WindowMaterials = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'window_materials'});
        }
    }, [isSectionVisible]);

    return <>
        <Typography variant={'h3'} sx={{mt: 5, px: 2, fontSize: isMobile ? '1.8em' : '3em'}}>
            Here's Why We Use Exclusive Fibrex Material!
        </Typography>
        <Grid xs={12} md={6} sx={{textAlign: 'center'}}>
            <Stack sx={{mt: 5}}>
                <Grid container>
                    <Grid xs={2} xsOffset={2}><Brightness5Icon sx={{fontSize: '48px'}}/></Grid>
                    <Grid xs={6} sx={{textAlign: 'left'}}>
                        <Typography sx={{color: '#408740', fontSize: '22px'}}>Smart Materials</Typography>
                        <Typography>
                        Fibrex material retains its stability and rigidity in all climates. Its strength
                        means thinner frames, more glass area, and <b>more natural light</b>.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{mt: 10}}>
                    <Grid xs={2} xsOffset={2}><ThermostatIcon sx={{fontSize: '48px'}}/></Grid>
                    <Grid xs={6} sx={{textAlign: 'left'}}>
                        <Typography sx={{color: '#408740', fontSize: '22px'}}>Exceptional Comfort</Typography>
                        <Typography>
                            Fibrex material blocks thermal transfer and seals stay weathertight to help <b>reduce
                            heating and cooling bills</b>.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{mt: 10}}>
                    <Grid xs={2} xsOffset={2}><HandymanIcon sx={{fontSize: '48px'}}/></Grid>
                    <Grid xs={6} sx={{textAlign: 'left'}}>
                        <Typography sx={{color: '#408740', fontSize: '22px'}}>Outstanding Durability</Typography>
                        <Typography>
                            Fibrex material resists rot, decay, and fungal growth and <b>
                            requires little maintenance</b><sup>1</sup>.
                        </Typography>
                    </Grid>
                </Grid>
            </Stack>
        </Grid>
        <Grid xs={12} md={6} sx={{my: 5}}>
            <Box
                component={'img'}
                alt="Renewal by Anderson"
                src={WindowCornerImage}
                width={'100%'}
                sx={{px: isMobile ? 2 : 10}}
            />
        </Grid>
    </>;
};

export default WindowMaterials;
