import {zodResolver} from '@hookform/resolvers/zod';
import {Button, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import {RhfCheckboxGroup, RhfRadioGroup} from 'mui-rhf-integration';
import type {Dispatch, SetStateAction} from 'react';
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {usePostQuizAnswersMutation} from '@/mutations/lead-token';
import type {LeadToken} from '@/types/lead-token';
import type {QuizStepType} from '@/types/quiz';
import {errorMap} from '@/utils/zod';

type Props = {
    leadToken : LeadToken;
    step : QuizStepType;
    setCurrentStepIndex : Dispatch<SetStateAction<number>>;
};

const QuizStep = ({leadToken, step, setCurrentStepIndex} : Props) : React.ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const quizAnswersMutation = usePostQuizAnswersMutation(leadToken);

    const schema = z.object({
        [step.stepKey]: step.multiselect ? z.string().array() : z.string(),
    });

    type FormValues = z.infer<typeof schema>;

    const form = useForm<FormValues>({
        resolver: zodResolver(schema, {errorMap}),
    });

    useEffect(() => {
        if (leadToken.quizAnswers[step.stepKey]) {
            setCurrentStepIndex(step.stepNumber + 1);
        }
    }, [step]);

    const handleSubmit = async (values : FormValues) => {
        await quizAnswersMutation.mutateAsync(values, {
            onSuccess: () => {
                setCurrentStepIndex(step.stepNumber + 1);
            },
        });
    };

    const sharedInputStyles = {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: isMobile ? 'center' : 'space-between',
        'label.MuiFormControlLabel-labelPlacementEnd': {
            border: '1px solid',
            justifyContent: 'center',
            mx: '10px',
            marginBottom: '10px',
            width: '200px',
            height: 20,
            backgroundColor: '#f7f6f6',
            padding: 3,
        },
        '.MuiFormGroup-root': {
            flexDirection: isMobile ? 'column' : 'row',
        },
        '.MuiTypography-root': {
            fontSize: '18px',
            lineHeight: 1,
        },
    };

    return <Grid container xs={12} component={'form'} onSubmit={form.handleSubmit(handleSubmit)} sx={{
        mt: isMobile ? 2 : 6,
        mx: isMobile ? 2 : 10,
        textAlign: 'center',
        alignItems: 'center',
    }}>
        <Grid xs={12} md={5} sx={{px: isMobile ? 0 : 6}}>
            <Typography variant={'h4'} sx={{fontWeight: 400}}>{step.questionText}</Typography>
            {step.multiselect && <Typography variant={'body1'} sx={{fontWeight: 400}}>
                (select all that apply)
            </Typography>}
        </Grid>
        <Grid xs={12} md={7}>
            {step.multiselect
                ? <RhfCheckboxGroup
                    control={form.control}
                    name={step.stepKey}
                    label={''}
                    sx={{
                        ...sharedInputStyles,
                        'span.MuiCheckbox-root': {
                            display: 'none',
                        },
                        'label:has(input[type="checkbox"]:checked)': {
                            backgroundColor: '#6CC14C',
                        },
                    }}
                    options={step.options.map(option => ({
                        value: option.value,
                        label: option.displayText,
                    }))}
                />
                : <RhfRadioGroup
                    control={form.control}
                    name={step.stepKey}
                    label={''}
                    sx={{
                        ...sharedInputStyles,
                        'span.MuiRadio-root': {
                            display: 'none',
                        },
                        'label:has(input[type="radio"]:checked)': {
                            backgroundColor: '#6CC14C',
                        },
                    }}
                    options={step.options.map(option => ({
                        value: option.value,
                        label: option.displayText,
                    }))}
                />}
            <Grid xs={12} sx={{textAlign: isMobile ? 'center' : 'right'}}>
                <Button type={'submit'} variant={'contained'} sx={{
                    mr: '10px',
                    mt: 5,
                    borderRadius: 0,
                    fontSize: '14px',
                    py: 2,
                    px: 10,
                }} disabled={!form.formState.isValid}>
                    Next Question
                </Button>
            </Grid>
        </Grid>
    </Grid>;
};

export default QuizStep;
