import {Box, useTheme} from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import useMediaQuery from '@mui/material/useMediaQuery';
import type React from 'react';
import {useEffect, useRef} from 'react';
import FibrexLogo from '@/assets/fibrex_logo.png';
import FibrexTable from '@/components/FibrexTable';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';

const FibrexBenefitsSection = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('lg'));

    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'fibrex_benefits'});
        }
    }, [isSectionVisible]);

    return <Grid container xs={12}>
        <Grid xs={12} md={6}>
            <Box px={8} pt={ mobile ? 0 : 15}>
                <Typography variant={'h4'} sx={{fontWeight: 700}}>
                    3 Top Reasons Why Fibrex® is a Better Choice Than Vinyl
                </Typography>
                <ol>
                    <li>Our exclusive Fibrex composite material is low maintenance, resistant to
                        decay – and stronger than vinyl.</li>
                    <li>Our windows and doors require less framing material.</li>
                    <li>The superior thermal insulating properties, combined with High-Performance™ Low-E4®
                        glass, can help your home stay comfortable no matter the climate. It can stay warmer
                        in winter and cooler in summer<sup>3</sup>.</li>
                </ol>
                <Box
                    component={'img'}
                    alt="Fibrex Material | vastly superior to vinyl"
                    src={FibrexLogo}
                    width={'100%'}
                />
            </Box>
        </Grid>
        <Grid xs={12} md={6}>
            <Grid mt={10} mr={mobile ? 0 : 5}>
                <FibrexTable/>
            </Grid>
        </Grid>
    </Grid>;
};

export default FibrexBenefitsSection;
