import Grid from '@mui/material/Unstable_Grid2';
import type React from 'react';
import {useEffect, useRef, useState} from 'react';
import QuizOfferStep from '@/components/QuizOfferStep';
import QuizProgressBar from '@/components/QuizProgressBar';
import QuizStep from '@/components/QuizStep';
import QuizZipStep from '@/components/QuizZipStep';
import useOnScreen from '@/hooks/useOnScreen';
import {useCreateLeadTokenEventMutation} from '@/mutations/lead-token-event';
import type {LeadToken} from '@/types/lead-token';
import {quizJson} from '@/types/quiz';

const Quiz = ({leadToken} : {leadToken : LeadToken}) : React.ReactElement => {
    const [currentStepIndex, setCurrentStepIndex] = useState(1);
    const currentStep = quizJson.steps.find(step => step.stepNumber === currentStepIndex) ?? quizJson.steps[0];
    const eventMutation = useCreateLeadTokenEventMutation();

    const ref = useRef<HTMLElement>(null);
    const isSectionVisible = useOnScreen(ref);

    useEffect(() => {
        if (isSectionVisible) {
            eventMutation.mutate({leadTokenId: leadToken.id, type: 'scroll', name: 'quiz'});
        }
    }, [isSectionVisible]);

    return <Grid container xs={12} sx={{mb: 5}}>
        <Grid xs={12} sx={{
            mt: 10,
            textAlign: 'center',
        }}>
            <QuizProgressBar stepNumber={currentStepIndex}/>
        </Grid>
        {currentStepIndex < 5 && <QuizStep
            leadToken={leadToken}
            step={currentStep}
            setCurrentStepIndex={setCurrentStepIndex}
            key={currentStep.stepNumber}
        />}
        {currentStepIndex === 5 && <QuizZipStep leadToken={leadToken} setCurrentStepIndex={setCurrentStepIndex}/>}
        {currentStepIndex === 6 && <QuizOfferStep leadToken={leadToken}/>}
    </Grid>;
};

export default Quiz;
