type Quiz = {
    steps : QuizStepType[];
};
export type QuizStepType = {
    stepNumber : number;
    stepKey : 'services' | 'age' | 'problems' | 'features';
    questionText : string;
    multiselect : boolean;
    options :
    QuizOption[];
};
export type QuizOption = {
    value : string;
    displayText : string;
    icon ?: string;
};
export type QuizAnswers = {
    services ?: string;
    age ?: string;
    problems ?: [];
    features ?: [];
};
export const quizJson : Quiz = {
    steps: [
        {
            stepNumber: 1,
            stepKey: 'services',
            questionText: 'What replacement services are you looking for?',
            multiselect: false,
            options: [
                {
                    value: 'window',
                    displayText: 'Window',
                    icon: '',
                },
                {
                    value: 'door',
                    displayText: 'Door',
                    icon: '',
                },
                {
                    value: 'both',
                    displayText: 'Both',
                    icon: '',
                },
            ],
        },
        {
            stepNumber: 2,
            stepKey: 'age',
            questionText: 'How old are your current windows and doors?',
            multiselect: false,
            options: [
                {
                    value: '<5',
                    displayText: 'Less than 5 years',
                },
                {
                    value: '5-10',
                    displayText: '5 to 10 years',
                },
                {
                    value: '10-20',
                    displayText: '10 to 20 years',
                },
                {
                    value: '>20',
                    displayText: 'Over 20 years',
                },
            ],
        },
        {
            stepNumber: 3,
            stepKey: 'problems',
            questionText: 'What problems are you experiencing with your current windows or doors?',
            multiselect: true,
            options: [
                {
                    value: 'style',
                    displayText: 'Style',
                },
                {
                    value: 'operate',
                    displayText: 'Hard to operate',
                },
                {
                    value: 'draft',
                    displayText: 'Drafts or leaks',
                },
                {
                    value: 'damage',
                    displayText: 'Cloudiness or mold',
                },
                {
                    value: 'energy',
                    displayText: 'High energy cost',
                },
            ],
        },
        {
            stepNumber: 4,
            stepKey: 'features',
            questionText: 'What window and door features are you looking for?',
            multiselect: true,
            options: [
                {
                    value: 'heat',
                    displayText: 'Extreme heat resistance',
                },
                {
                    value: 'operation',
                    displayText: 'Ease of operation',
                },
                {
                    value: 'warranty',
                    displayText: 'Warranty policy',
                },
                {
                    value: 'light',
                    displayText: 'Natural light',
                },
                {
                    value: 'energy',
                    displayText: 'Energy efficiency',
                },
            ],
        },
    ],
};
